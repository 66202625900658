import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Alert, getError } from 'core/helper'
import { Http } from 'core/helper/Http'

export const StoreTask = createAsyncThunk('StoreTask/tasks', async (data, { rejectWithValue }) => {
	try {
		const res = await Http(`task`, {
			method: 'post',
			data,
		})
		if (res.status === 200) {
			Alert.SUCCESS(res.data.data)
			return {
				data: res.data,
			}
		} else {
			throw new Error(res.data.error)

			// return {
			// 	data: rejectWithValue(res.response.data.error),
			// 	isOk: false,
			// }
		}
	} catch (error) {
		Alert.ERROR(getError(error))

		rejectWithValue(getError(error))
	}
})

export const StoreTaskSlice = createSlice({
	name: 'StoreTask',
	initialState: {
		loading: false,
		error: {},
		isSuccess: null,
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(StoreTask.pending, (state, action) => {
				state.loading = true
				state.isSuccess = null
			})
			.addCase(StoreTask.fulfilled, (state, action) => {
				state.loading = false
				state.isSuccess = true
			})
			.addCase(StoreTask.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
				state.isSuccess = false
			}),
})

export default StoreTaskSlice.reducer
