import { ModalCore } from 'components/core'
import { Box, Button, Stack } from '@mui/material'
import CoreBTN from 'components/core/CoreBTN'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CustomInput from 'components/Input/CustomInput'
import Select from 'react-select'
import { DocumentChangeStatus } from '@/core/redux/features/document/DocumentChangeStatusSlice'
import { showArticle } from '@/core/redux/features/document/article/showArticelSlice'
import { storeFromDraft } from '@/core/redux/features/document/draft/storeFromDraftSlice'
import { useHistory } from 'react-router-dom'
import { _publishDms, _rejectDms, publishDms } from '@/core/redux/features/dms/update'

const DocumentChangeStatusModal = ({
	document,
	callback = () => {},
	openModal,
	setOpenModal,
	type = '',
}) => {
	const isDraft = type === 'draft'
	const isDmsTemplate = type === 'dms_template'
	const isDms = type === 'dms' || isDmsTemplate

	const history = useHistory()

	const dispatch = useDispatch()

	const [selectedStatus, setSelectedStatus] = useState(null)
	const [submit, setSubmit] = useState(null)
	const { t } = useTranslation()
	const { loading, isSuccess } = useSelector((state) => state.DocumentChangeStatusSlice)
	const { loading: draftLoading, isSuccess: draftIsSuccess } = useSelector(
		(state) => state.storeFromDraftSlice
	)
	const { loading: articleLoading, isSuccess: articleIsSuccess } = useSelector(
		(state) => state.showArticleSlice
	)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()

	useEffect(() => {
		setSelectedStatus({ label: document?.status, value: document?.status_type })
	}, [document])

	const options = [
		{
			label: t('reject'),
			value: 'reject',
		},
		{
			label: t('accept'),
			value: 'accept',
		},
		{
			label: t('published'),
			value: 'publish',
		},
		{
			label: t('need_review'),
			value: 'needReview',
		},
	]

	useEffect(() => {
		if ((isSuccess || draftIsSuccess) && submit) {
			setOpenModal(false)
		}
	}, [submit, isSuccess, draftIsSuccess])

	const onSubmit = (data) => {
		dispatch(
			DocumentChangeStatus({
				id: document?.id,
				comment: data?.comment,
				type: selectedStatus?.value,
			})
		).then((res) => {
			setSubmit(true)
			dispatch(showArticle({ id: document?.id, page: 1, perPage: 5 }))
		})
		setSubmit(false)
	}

	const publishDraft = () => {
		dispatch(
			storeFromDraft({
				document_id: document.id,
				visibility: document.visibility_type,
				users: document.users,
				tags: document.tags,
				teams: document.teams,
				projects: document.projects,
				tasks: document.tasks,
				drives: document.drives,
			})
		).then((res) => {
			setSubmit(true)
			dispatch(showArticle({ id: document?.id, page: 1, perPage: 5 }))
				.unwrap()
				.then((r) => {
					history.push(`/document/${document.type}/${document.id}`)
				})
		})
		setSubmit(false)
	}

	const publishDms = () => {
		dispatch(_publishDms({ id: document.id }))
			.unwrap()
			.then((r) => {
				callback?.()
			})
	}

	const rejectDms = () => {
		dispatch(_rejectDms({ id: document.id }))
			.unwrap()
			.then((r) => {
				callback?.()
			})
	}

	return (
		<>
			<ModalCore title={t('Edit')} open={openModal} size='600px' setOpen={setOpenModal}>
				<form onSubmit={handleSubmit(onSubmit)}>
					{!isDraft && !isDms && (
						<Stack direction={'column'} spacing={2}>
							<Select
								className='React_select'
								value={selectedStatus}
								onChange={setSelectedStatus}
								options={options}
								placeholder={t('Select')}
							/>
							<CustomInput
								errors={errors}
								register={register}
								title={'comment'}
								field={'comment'}
								required={true}
							/>
						</Stack>
					)}

					<Box display={'flex'} justifyContent={'center'} alignItems={'center'} mt={4}>
						{!!isDraft ? (
							<Button
								type={'button'}
								sx={{ borderRadius: 15, padding: '5px 12px', margin: '0 15px' }}
								variant={'contained'}
								color={'primary'}
								fullWidth
								onClick={() => publishDraft()}
							>
								{t('Publish_From_Draft')}
							</Button>
						) : (
							<>
								{!!isDms ? (
									<>
										<Button
											type={'button'}
											sx={{ borderRadius: 15, padding: '5px 12px', margin: '0 15px' }}
											variant={'contained'}
											color={'success'}
											fullWidth
											onClick={() => publishDms()}
										>
											{t('Publish_DMS')}
										</Button>
										<Button
											type={'button'}
											sx={{ borderRadius: 15, padding: '5px 12px', margin: '0 15px' }}
											variant={'contained'}
											color={'error'}
											fullWidth
											onClick={() => rejectDms()}
										>
											{t('Reject_DMS')}
										</Button>
									</>
								) : (
									<CoreBTN
										sx={{ borderRadius: 15, padding: '5px 12px', width: '100%' }}
										variant={'contained'}
										color={'primary'}
										title={t('Confirmation')}
										type={'submit'}
										loading={loading}
										disabled={document.status_type === selectedStatus?.value}
									/>
								)}
							</>
						)}
						<Button
							sx={{ borderRadius: 15, padding: '5px 12px', margin: '0 15px' }}
							variant={'contained'}
							color={'secondary'}
							fullWidth
							onClick={() => setOpenModal(false)}
						>
							{t('Cancel')}
						</Button>
					</Box>
				</form>
			</ModalCore>
		</>
	)
}
export default DocumentChangeStatusModal
