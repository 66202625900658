import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { _searchAttribute } from '@/core/redux/features/dms/attributes/search'
import {
	Autocomplete,
	Box,
	CircularProgress,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ErrorCore } from '../core'
import { useDebounce } from 'use-debounce'

const AttributeInput = ({
	form = {},
	title = '',
	errors = {},
	field = '',
	defaultValue = '',
	value = '',
	...rest
}) => {
	const ref = useRef(null)
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const { setValue } = form

	const [finalValue, setFinalValue] = useState(defaultValue || value)

	const [debouncedValue] = useDebounce(finalValue, 750)

	const [open, setOpen] = React.useState(false)
	const [searchResults, setSearchResults] = useState([])

	const [loading, setLoading] = useState(open && searchResults.length === 0)

	const searchAttribute = (query) => {
		setFinalValue(query)

		if (query) {
			setLoading(true)

			dispatch(_searchAttribute({ query }))
				.unwrap()
				.then((r) => {
					if (r?.data?.data?.length) {
						setSearchResults(r?.data?.data)
					} else {
						setSearchResults([])
					}
				})
				.catch((e) => {
					setSearchResults([])
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}

	const isFocused = () => {
		return document?.activeElement === ref?.current
	}

	useEffect(() => {
		setValue?.(field, finalValue)
	}, [finalValue])

	useEffect(() => {
		if (isFocused()) searchAttribute(debouncedValue)
	}, [debouncedValue])

	// console.log({ defaultValue, value, finalValue })

	return (
		<div>
			<Autocomplete
				id='attribute-input'
				open={open}
				defaultValue={{ name: defaultValue || value }}
				value={{ name: finalValue }}
				onOpen={() => {
					setOpen(true)
				}}
				onClose={() => {
					setOpen(false)
				}}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				getOptionLabel={(option) => option.name}
				options={searchResults}
				loading={loading}
				renderInput={(params) => (
					<TextField
						inputRef={ref}
						{...rest}
						{...params}
						onChange={(e) => {
							setFinalValue(e.target.value)
						}}
						defaultValue={defaultValue || value}
						value={value}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color='inherit' size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
				freeSolo
				onChange={(e, value) => {
					if (value?.name) {
						setFinalValue(value.name)
					}
				}}
			/>
		</div>
	)
}

export default AttributeInput
