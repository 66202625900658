import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Alert } from 'core/helper'
import { Http } from 'core/helper/Http'

export const storeDms = createAsyncThunk(
	'document/storeDms',
	async ({ files = [], attachments = [], ...data }, { rejectWithValue }) => {
		try {
			// let formData = new FormData()
			//
			// for (const property in data) {
			// 	const value = data[property]
			// 	if (!!value) {
			// 		formData.append(property, value)
			// 	}
			// }
			//
			// files?.length &&
			// 	files.forEach(({ file }) => {
			// 		formData.append('files[]', file)
			// 	})

			const drives = attachments.map(({ id }) => {
				return id
			})

			const res = await Http(`/document/store/dms`, {
				method: 'post',
				data: { ...data, drives },
			})
			if (res.status === 200) {
				Alert.SUCCESS(res.data.message)
				return {
					data: res.data,
					isOk: true,
				}
			} else {
				throw new Error(res.response.data.error)

				// return {
				// 	data: rejectWithValue(res.response.data.error),
				// 	isOk: false,
				// }
			}
		} catch (error) {
			Alert.ERROR(error?.message || error?.response?.data?.error || '')
			rejectWithValue(error.response.data.error)
		}
	}
)

export const storeDmsSlice = createSlice({
	name: 'StoreDms',
	initialState: {
		loading: false,
		error: {},
		isSuccess: null,
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(storeDms.pending, (state, action) => {
				state.loading = true
				state.isSuccess = null
			})
			.addCase(storeDms.fulfilled, (state, action) => {
				state.loading = false
				state.isSuccess = action?.payload?.isOk
				state.data = action?.payload?.data
			})
			.addCase(storeDms.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
				state.isSuccess = false
			}),
})

export default storeDmsSlice.reducer
