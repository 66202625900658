import { useState } from 'react'

export const useDialog = ({ isOpen = false } = {}) => {
	const [open, setOpen] = useState(isOpen)

	return {
		open,
		setOpen,
	}
}
