import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Alert } from 'core/helper'
import { Http } from 'core/helper/Http'

export const _searchGlobal = createAsyncThunk(
	'document/_searchGlobal',
	async ({ query }, { rejectWithValue }) => {
		try {
			const res = await Http(`/document/search/general`, {
				method: 'get',
				params: { search: query },
			})
			if (res.status === 200) {
				return {
					data: res.data,
					isOk: true,
				}
			} else {
				throw new Error(res.response.data.error)

				// return {
				// 	data: rejectWithValue(res.response.data.error),
				// 	isOk: false,
				// }
			}
		} catch (error) {
			Alert.ERROR(error?.message || error?.response?.data?.error || '')
			rejectWithValue(error.response.data.error)
		}
	}
)

export const searchGlobalSlice = createSlice({
	name: 'SearchGlobal',
	initialState: {
		loading: false,
		error: {},
		isSuccess: null,
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(_searchGlobal.pending, (state, action) => {
				state.loading = true
				state.isSuccess = null
			})
			.addCase(_searchGlobal.fulfilled, (state, action) => {
				state.loading = false
				state.isSuccess = action?.payload?.isOk
				state.data = action?.payload?.data
			})
			.addCase(_searchGlobal.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
				state.isSuccess = false
			}),
})

export default searchGlobalSlice.reducer
