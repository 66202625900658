import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Alert } from 'core/helper'
import { Http } from 'core/helper/Http'

// ********************* create Team Role FUNCTIONALITY*********************

export const createTeamRole = createAsyncThunk(
	'CreateTeamRole/Team',
	async (data, { rejectWithValue }) => {
		try {
			const res = await Http(`teamRole`, {
				method: 'post',
				data,
			})
			if (res.status === 200) {
				Alert.SUCCESS(res.data.message)
				return {
					data: res.data,
				}
			}
			if (res.response.status === 422) {
				throw new Error(res.response.data.error)
			}
		} catch (error) {
			Alert.ERROR(error?.message || error?.response?.data?.error || '')
			rejectWithValue(error.response.data.error)
		}
	}
)

export const createTeamRoleSlice = createSlice({
	name: 'CreateTeamRole',
	initialState: {
		loading: false,
		error: {},
		isSuccess: null,
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(createTeamRole.pending, (state, action) => {
				state.loading = true
				state.isSuccess = null
			})
			.addCase(createTeamRole.fulfilled, (state, action) => {
				state.loading = false
				state.isSuccess = true
			})
			.addCase(createTeamRole.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
				state.isSuccess = false
			}),
})

export default createTeamRoleSlice.reducer
