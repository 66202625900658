import {
	Attachment,
	AudioFile,
	FolderZip,
	Image,
	PictureAsPdf,
	Videocam,
} from '@mui/icons-material'

export const attachmentTypes = [
	{ value: 'VIDEO', label: 'ویدئو' },
	{ value: 'IMAGE', label: 'عکس' },
	{ value: 'DOCUMENT', label: 'سند' },
	{ value: 'AUDIO', label: 'صوتی' },
	{ value: 'SUBTITLE', label: 'زیرنویس' },
	{ value: 'OTHER', label: 'سایر' },
]

export const getAttachmentTypesInfo = (type) => {
	const filter = attachmentTypes.filter((item) => item.value === type)
	return filter?.length ? filter[0] : {}
}

export const getIconByMimType = (path) => {
	let icon = <Attachment />
	let color = 'white'

	if (path) {
		const isPdf = path.indexOf('pdf') >= 0
		const isImage = path.indexOf('png') >= 0 || path.indexOf('jpg') >= 0
		const isVideo = path.indexOf('mp4') >= 0 || path.indexOf('mkv') >= 0
		const isCompressed = path.indexOf('octet-stream') >= 0 || path.indexOf('zip') >= 0
		const isAudio = path.indexOf('mp3') >= 0

		if (isPdf) {
			icon = <PictureAsPdf />
			color = 'red'
		} else if (isImage) {
			icon = <Image />
			color = 'blue'
		} else if (isVideo) {
			icon = <Videocam />
			color = 'info'
		} else if (isCompressed) {
			icon = <FolderZip />
			color = 'secondary'
		} else if (isAudio) {
			icon = <AudioFile />
			color = 'green'
		}
	}

	return { icon, color }
}
