import {
	Style,
	Pages,
	IndeterminateCheckBox,
	AddBox,
	Grade,
	Drafts,
	DocumentScanner,
	Unarchive,
	FolderOpen,
	Category,
} from '@mui/icons-material'
import styled from 'styled-components'
import { Item } from 'components/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { SidebarItem } from '../index'
import { Accordion, AccordionDetails, AccordionSummary, Divider } from '@mui/material'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { CheckRole } from '../../core/helper'

const DmsSide = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const [setting, setSetting] = React.useState(true)
	const { data: user } = useSelector((state) => state.user)

	return (
		<>
			<Divider sx={{ margin: '5px 0 7px 0' }} />
			<Item style={{ padding: '0', width: '85%', margin: '10px auto' }}>
				<SidebarItem
					text={t('Document')}
					icon={<FolderOpen />}
					onClick={() => history.push('/dms')}
				/>
			</Item>
			<Item style={{ padding: '0', width: '85%', margin: '10px auto' }}>
				<SidebarItem
					text={t('Categories')}
					icon={<Category />}
					onClick={() => history.push('/dms/categories')}
				/>
			</Item>
			{CheckRole({ roles: user?.data?.type, page: 'dms', part: 'side' }) && (
				<Accordion
					sx={{ boxShadow: 'none', borderTop: 'none' }}
					expanded={setting}
					// onClick={() => setSetting(!setting)}
				>
					<AccordionSummary>
						<SidebarItem
							text={t('template')}
							icon={setting ? <IndeterminateCheckBox sx={{ mr: 1 }} /> : <AddBox sx={{ mr: 1 }} />}
						/>
					</AccordionSummary>
					<AccordionDetails>
						<Box sx={{ mt: 2, textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
							<Item>
								<SidebarItem
									text={t('DMS_Template')}
									icon={<Pages />}
									onClick={() => history.push('/dms/templates')}
								/>
							</Item>
							<Item>
								<SidebarItem
									text={t('DMS_Template_Categories')}
									icon={<Category />}
									onClick={() => history.push('/dms/templates/categories')}
								/>
							</Item>
						</Box>
					</AccordionDetails>
				</Accordion>
			)}
		</>
	)
}

export default DmsSide

const Ancer = styled(Link)`
	display: inline-flex;
	align-items: center;
`
