import { useTheme } from '@mui/material/styles'
import { Button, Divider, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { userExitTime } from '@/core/redux/features/users/userExitSlice'
import { userArrivalTime } from '@/core/redux/features/users/setArrivalTimeSlice'
import { getUserInfo } from '@/core/redux/features/users/userSlice'
import Avatar from '../avatar'
import { userArrivalDte } from '@/core/redux/features/users/userArrivalDateSlice'
const UserArrivalSide = () => {
	const { data } = useSelector((state) => state.user)

	const theme = useTheme()
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const dir = t('direction') === 'rtl'

	const HandleArrivalUser = async () => {
		dispatch(userArrivalTime())
			.unwrap()
			.then((r) => {
				dispatch(getUserInfo())
				dispatch(userArrivalDte())
			})
	}
	const HandleExitUser = async () => {
		dispatch(userExitTime())
			.unwrap()
			.then((r) => {
				dispatch(getUserInfo())
				dispatch(userArrivalDte())
			})
	}

	// data.data.today_log.arrival
	// data.data.today_log.out

	const hasArrived = !!data?.data?.today_log?.arrival
	const hasLeft = !!data?.data?.today_log?.out

	const hasSubmitted = hasArrived && hasLeft

	return (
		<>
			<Avatar
				address={data?.data?.avatar}
				size={'xl'}
				badge={true}
				badgeColor={data?.data?.today_arrival}
			/>

			<Box display={'flex'} justifyContent={'center'}>
				<Typography
					mt={2}
					component={'span'}
					color={theme.palette.mode === 'dark' ? 'primary.dark' : 'secondary'}
				>
					{data?.data?.first_name}
				</Typography>
				<Typography
					mt={2}
					style={dir ? { marginRight: 4 } : { marginLeft: 4 }}
					component={'span'}
					color={theme.palette.mode === 'dark' ? 'primary.dark' : 'secondary'}
				>
					{data?.data?.last_name}
				</Typography>
			</Box>

			<Typography sx={{ textAlign: 'center' }} mt={2} component={'p'} color={'primary'}>
				{t('Your_user_status')}
			</Typography>
			{hasSubmitted ? (
				<Button
					color={'secondary'}
					variant={'outlined'}
					disabled={true}
					sx={{
						width: '100%',
						borderRadius: 4,
						margin: '15px 0',
					}}
					// onClick={HandleArrivalUser}
				>
					{t('EnterAndExitHoursSubmitted')}
				</Button>
			) : (
				<>
					{!hasArrived ? (
						<Button
							sx={{
								width: '100%',
								borderRadius: 4,
								margin: '15px 0',
								borderColor: '#1ce087',
								color: '#fff',
								background: '#1ce087',
								':hover': {
									background: '#fff',
									color: '#1ce087',
									borderColor: '#1ce087',
								},
							}}
							variant={'outlined'}
							onClick={HandleArrivalUser}
						>
							{t('EnterArrivalHour')}
						</Button>
					) : (
						<Button
							sx={{
								width: '100%',
								borderRadius: 4,
								margin: '15px 0',
								borderColor: '#ce1440',
								color: '#fff',
								background: '#ce1440',
								':hover': {
									background: '#fff',
									color: '#ce1440',
									borderColor: '#ce1440',
								},
							}}
							variant={'outlined'}
							onClick={HandleExitUser}
						>
							{t('EnterExitHour')}
						</Button>
					)}
				</>
			)}

			<Box display={'flex'} justifyContent={'center'} flexDirection={'column'} textAlign={'center'}>
				<Typography mt={2} component={'p'} color={'primary'}>
					{parseInt(data?.data?.type?.length) > 1 ? t('Your_Roles') : t('Your_Role')}
				</Typography>
				{data?.data?.type?.map((row, i) => (
					<Typography
						mt={1}
						component={'span'}
						color={theme.palette.mode === 'dark' ? 'primary.dark' : 'secondary'}
					>
						{t(row)}
					</Typography>
				))}
				<divider />
			</Box>
		</>
	)
}

export default UserArrivalSide
