import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { Http } from 'core/helper/Http'

export const myDriveList = createAsyncThunk('MyDriveList/List', async ({ search } = {}) => {
	const res = await Http(`document/myDrives`, {
		method: 'get',
		params: {
			unique: search,
		},
	})
	if (res.status === 200) {
		return {
			data: res.data.data,
		}
	}
})

export const myDriveListSlice = createSlice({
	name: 'MyDriveList',
	initialState: {
		loading: false,
		error: {},
		data: [],
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(myDriveList.pending, (state) => {
				state.loading = true
			})
			.addCase(myDriveList.fulfilled, (state, action) => {
				state.loading = false
				state.data = action?.payload?.data
			})
			.addCase(myDriveList.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			}),
})

export default myDriveListSlice.reducer
