import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import React from 'react'
import { AttrSectionItem } from './item'

export const AttrSections = ({ type = '' }) => {
	const isTemplate = type === 'template'

	const { data: dms, loading } = useSelector((state) =>
		!isTemplate ? state.showDmsSlice : state.showDmsTemplateSlice
	)

	const { attributes } = dms

	return (
		<>
			<Grid container spacing={3}>
				{!!attributes?.length && (
					<>
						{attributes.map((item, index) => {
							return (
								<Grid item xs={12} sm={6} key={index}>
									<AttrSectionItem type={type} data={item} />
								</Grid>
							)
						})}
					</>
				)}
			</Grid>
		</>
	)
}
