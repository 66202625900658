import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Http, Params } from 'core/helper'

export const ownIndexDocument = createAsyncThunk(
	'OwnIndexDocument/OwnIndex',
	async ({ page = 1, perPage = 10, filters = null, search = null } = {}) => {
		const url = Params(`document/index/own`, { page, perPage, filters, search })

		const res = await Http(url, {
			method: 'get',
		})
		if (res.status === 200) {
			return { data: res.data.data }
		} else {
			return res.response.data.error
		}
	}
)

const ownIndexDocumentSlice = createSlice({
	name: 'OwnIndexDocument',
	initialState: {
		data: [],
		loading: false,
		error: {},
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(ownIndexDocument.pending, (state) => {
				state.loading = true
			})
			.addCase(ownIndexDocument.fulfilled, (state, action) => {
				state.data = action?.payload?.data
				state.loading = false
			})
			.addCase(ownIndexDocument.rejected, (state, action) => {
				state.data = null
				state.loading = false
				state.error = action.payload
			}),
})

export default ownIndexDocumentSlice.reducer
