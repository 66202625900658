import {
	Alert,
	Box,
	Button,
	Checkbox,
	Chip,
	Container,
	Divider,
	Grid,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
} from '@mui/material'
import { AlignItems, ArrowControl, Body, Header } from '@/pages/document/Article/Article-Styled'
import {
	ArrowDropDown,
	ArrowLeft,
	ArrowRight,
	Bookmark,
	BookmarkBorder,
	ContentCopy,
	ContentCopyTwoTone,
	Edit,
	EventNote,
	FolderOpenOutlined,
} from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CheckRole, getDocStatus } from 'core/helper'
import DocumentChangeStatusModal from '../../pages/document/DocumentChangeStatusModal'
import DmsSidebar from './sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { AttrSections } from './update/sections'
import { showDms } from '@/core/redux/features/dms/show'
import { DmsTemplatesShow } from '@/core/redux/features/dms/templates/show'
import { ComposeSection } from '@/components/dms/compose/sections'
import useStateListener from '@/core/hooks/useStateListener'
import { DynamicForm } from '@/components/dms/dynamic-form'

const DmsBody = ({ user, type = '', status = '' }) => {
	const isTemplate = type === 'template'

	const { id } = useParams()

	const dispatch = useDispatch()
	const { t } = useTranslation()
	const history = useHistory()
	const matches = useMediaQuery('max-width:890px')

	const { data, loading } = useSelector((state) =>
		!isTemplate ? state.showDmsSlice : state.showDmsTemplateSlice
	)

	const [sections, setSections] = useStateListener(data?.attributes || [], [data])

	const [dms, setDms] = useState({})
	const [openStatusModal, setOpenStatusModal] = useState(false)
	const [showSide, setShowSide] = useState(isTemplate ? false : true)

	const refresh = () => {
		const dispatcher = isTemplate ? DmsTemplatesShow : showDms

		dispatch(dispatcher({ id: data.id, page: 1, perPage: 5 }))
	}

	useEffect(() => {
		if (matches) return setShowSide(false)
	}, [matches])

	useEffect(() => {
		setDms(data)
	}, [data])

	const updateDoc = () => {
		return history.push({
			pathname: `/dms${isTemplate ? '/templates' : ''}/update/${id}`,
			state: { data, user, id },
		})
	}

	return (
		<>
			<Box sx={{ mt: 8 }}>
				<Container maxWidth={'xl'}>
					{!data ? (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'Center',
								height: '80vh',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
						>
							<img src='/assets/images/Lawyer-bro.png' alt='Article Not Found' />
							<Typography mt={2}>{t('DOC_NOTFOUND')}</Typography>
						</Box>
					) : (
						<Grid container spacing={5}>
							<Grid item xs={12} md={showSide ? 9 : 12}>
								<Box position={'relative'} sx={{ paddingLeft: 4.4 }}>
									<Header mt={10}>
										<Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 2 }}>
											<Typography fontWeight={'bold'} component={'h3'} variant={'h5'}>
												{data?.title}
											</Typography>
											{data?.is_new && (
												<Chip label={t('New')} variant={'outlined'} color={'success'} />
											)}
										</Box>

										<Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 2 }}>
											{(CheckRole({
												roles: user?.type,
												page: 'document',
												part: 'edit',
											}) ||
												data?.user?.id === user?.id) && (
												<Button variant={'contained'} color={'primary'} onClick={updateDoc}>
													<Edit sx={{ fontSize: 15, margin: '0 5px' }} />
													{t('Edit')}
												</Button>
											)}
										</Box>
									</Header>

									<Header mt={29}>
										<Stack direction={'row'} gap={1.5}>
											<AlignItems>
												<EventNote />
												<span>{data?.updated_at}</span>
											</AlignItems>
										</Stack>

										<Stack direction={'row'} gap={1} alignItems={'center'}>
											<AlignItems>
												<span>{data?.category?.title}</span>
												<FolderOpenOutlined />
											</AlignItems>
										</Stack>

										{!isTemplate && (
											<Stack direction={'row'} gap={1} alignItems={'center'}>
												<Box
													sx={
														CheckRole({
															roles: user?.type,
															page: 'document',
															part: 'changeStatus',
														})
															? { display: 'flex', cursor: 'pointer' }
															: { display: 'flex' }
													}
													onClick={() => {
														if (
															CheckRole({
																roles: user?.type,
																page: 'dms',
																part: 'changeStatus',
															})
														) {
															if (data?.status_type !== 'writing') {
																setOpenStatusModal(true)
															}
														}
													}}
												>
													{getDocStatus(data?.status_type, data?.status, true)}
												</Box>
											</Stack>
										)}
									</Header>

									<Box sx={{ display: 'inline-flex', gap: 1, mt: 1 }}>
										<Typography>{t('Description')}</Typography>:
										<Typography fontWeight={400} fontSize={14} lineHeight={1.9}>
											{data?.description?.substring(0, 250)}
										</Typography>
									</Box>

									<Divider sx={{ margin: '15px 0', borderColor: '#e8e8e8' }} />

									{data?.body === null ? (
										<Box sx={{ display: 'flex', justifyContent: 'center', margin: '100px 0' }}>
											<Typography>
												<Alert severity='success'> {data?.status}</Alert>
											</Typography>
										</Box>
									) : (
										<Body>
											<div
												className={'body__Html'}
												dangerouslySetInnerHTML={{ __html: `${data?.body}` }}
											/>
										</Body>
									)}

									<Divider sx={{ margin: '15px 0', borderColor: '#e8e8e8' }} />

									<AttrSections type={type} />

									{!isTemplate && (
										<Box>
											<ArrowControl onClick={() => setShowSide((showSide) => !showSide)}>
												{showSide ? <ArrowLeft /> : <ArrowRight />}
											</ArrowControl>
											<Divider
												color={'#e8e8e8'}
												sx={{ position: 'absolute', left: 0, top: 0, borderColor: '#e8e8e8' }}
												variant={'fullWidth'}
												orientation={'vertical'}
											/>
										</Box>
									)}
								</Box>
							</Grid>

							{!!showSide && (
								<Grid item xs={12} md={showSide ? 3 : 12}>
									<DmsSidebar
										setShowSide={setShowSide}
										showSide={showSide}
										setOpeModal={() => {}}
										data={data}
										type={type}
									/>
								</Grid>
							)}
						</Grid>
					)}
				</Container>
			</Box>
			{openStatusModal && (
				<DocumentChangeStatusModal
					callback={refresh}
					type={'dms'}
					openModal={openStatusModal}
					setOpenModal={setOpenStatusModal}
					document={data}
				/>
			)}
		</>
	)
}

export default DmsBody
