import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Alert } from 'core/helper'
import { Http } from 'core/helper/Http'

export const storeSaveDocument = createAsyncThunk(
	'StoreSaveDocument/StoreTag',
	async (data, { rejectWithValue }) => {
		try {
			const formData = new FormData()

			for (const [key, value] of Object.entries(data)) {
				formData.append(key, value)
			}

			const res = await Http(`/document/saved/store`, {
				method: 'post',
				data: formData,
			})
			if (res.status === 200) {
				return {
					data: res.data,
				}
			} else {
				throw new Error(res.response.data.error)

				// return {
				// 	data: rejectWithValue(res.response.data.error),
				// 	isOk: false,
				// }
			}
		} catch (error) {
			Alert.ERROR(error?.message || error?.response?.data?.error || '')
			rejectWithValue(error.response.data.error)
		}
	}
)

export const storeSaveDocumentSlice = createSlice({
	name: 'StoreSaveDocument',
	initialState: {
		loading: false,
		error: {},
		isSuccess: null,
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(storeSaveDocument.pending, (state, action) => {
				state.loading = true
				state.isSuccess = null
			})
			.addCase(storeSaveDocument.fulfilled, (state, action) => {
				state.loading = false
				state.isSuccess = true
			})
			.addCase(storeSaveDocument.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
				state.isSuccess = false
			}),
})

export default storeSaveDocumentSlice.reducer
