import { Box, Grid, List } from '@mui/material'
import React from 'react'
import { AttributeItem } from '../attributes/item'

export const AttrSectionItem = ({ type = '', data = {} }) => {
	const isTemplate = type === 'template'

	const { section, title, detail, id } = data

	return (
		<Box display={'flex'} gap={2} sx={{ flexDirection: 'column' }}>
			<Box
				sx={{ width: '100%' }}
				display={'flex'}
				alignItems={'center'}
				justifyContent={'space-between'}
			>
				<b>{section || title}</b>
			</Box>

			<List>
				<Grid container gap={2}>
					{isTemplate ? (
						<>
							{!!detail?.length && (
								<>
									{detail.map((item, index) => {
										let {
											default: defaultValue,
											edit,
											fieldName,
											repeatable,
											required,
											secondaryFieldName,
											secondaryFieldNumber,
											show,
											tagNumber,
										} = item

										let key = `#${tagNumber} - ${fieldName} - (${
											show ? 'فیلد نمایان' : 'فیلد مخفی'
										} - ${required ? 'فیلد اجباری' : ''} - ${
											edit ? 'قابل ویرایش' : 'غیرقابل ویرایش'
										} - ${repeatable ? 'تکرارپذیر' : 'تکرار ناپذیر'})`

										const data = { key, value: defaultValue }

										return (
											<Grid item xs={12} key={index}>
												<AttributeItem type={type} data={data} />
											</Grid>
										)
									})}
								</>
							)}
						</>
					) : (
						<>
							{!!detail && !!Object.keys(detail)?.length && (
								<>
									{Object.keys(detail).map((item, index) => {
										const data = { key: item, value: detail[item] }

										return (
											<Grid item xs={12} key={index}>
												<AttributeItem type={type} data={data} />
											</Grid>
										)
									})}
								</>
							)}
						</>
					)}
				</Grid>
			</List>
		</Box>
	)
}
