import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Alert } from 'core/helper'
import { Http } from 'core/helper/Http'

// ********************* USER Add FAQ  FUNCTIONALITY*********************

export const deleteFAQList = createAsyncThunk('DeleteFAQ/FAQ', async (id) => {
	const res = await Http(`faq/${id}`, {
		method: 'delete',
	})
	if (res.status === 200) {
		Alert.SUCCESS(res.data.message)
		return {
			data: res.data,
		}
	}
})

export const DeleteFAQSlice = createSlice({
	name: 'DeleteFAQ',
	initialState: {
		loading: false,
		error: {},
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(deleteFAQList.pending, (state, action) => {
				state.loading = true
			})
			.addCase(deleteFAQList.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(deleteFAQList.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			}),
})

export default DeleteFAQSlice.reducer
