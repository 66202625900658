import React, { Component, useEffect } from 'react'
import AttributeInput from '@/components/Input/AttributeInput'
import { useTranslation } from 'react-i18next'

export const AttributeInputRenderer = (props) => {
	const field = props.colDef?.field
	let colId = props.column.colId
	const defaultValue = props.data?.[field] || props.value || ''

	const { t } = useTranslation()

	const handleChange = (e) => {
		if (e?.target) {
			const { value, name } = e.target

			return props.node.setDataValue(colId, value)
		}

		props.node.setDataValue(colId, defaultValue)
	}

	useEffect(handleChange, [defaultValue])

	// console.log(field, colId, { defaultValue })

	return (
		<AttributeInput
			title={t('Attribute_Title')}
			id={`attribute-key-${props.column.colId}`}
			name={props?.column?.colDef.headerName || 'key'}
			onBlur={handleChange}
			defaultValue={defaultValue}
			value={defaultValue}
		/>
	)
}
