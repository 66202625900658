import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Alert } from 'core/helper'
import { Http } from 'core/helper/Http'

// ********************* USER Update FAQ  FUNCTIONALITY*********************
export const updateFAQList = createAsyncThunk(
	'UpdateFAQCustomer/UpdateFAQ',
	async ({ id, data }, { rejectWithValue }) => {
		try {
			const res = await Http(`faq/${id}`, {
				method: 'put',
				data,
			})
			if (res.status === 200) {
				Alert.SUCCESS(res.data.message)
				return {
					data: res.data,
				}
			} else {
				throw new Error(res.response.data.error)
			}
		} catch (error) {
			Alert.ERROR(error?.response?.data.error)
			rejectWithValue(error?.response?.data.error)
		}
	}
)

export const updateFAQSlice = createSlice({
	name: 'UpdateFAQCustomer',
	initialState: {
		loading: false,
		isSuccess: null,
		error: {},
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(updateFAQList.pending, (state) => {
				state.loading = true
				state.isSuccess = null
			})
			.addCase(updateFAQList.fulfilled, (state) => {
				state.loading = false
				state.isSuccess = true
			})
			.addCase(updateFAQList.rejected, (state, action) => {
				state.loading = false
				state.isSuccess = false
				state.error = action.payload
			}),
})

export default updateFAQSlice.reducer
