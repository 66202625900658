import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Http } from 'core/helper/Http'
// ********************* USER Add FAQ  FUNCTIONALITY*********************

export const getDetailsFAQList = createAsyncThunk('detailsFAQ/details', async (id) => {
	const res = await Http(`faq/${id}`, {
		method: 'get',
	})
	if (res.status === 200) {
		return {
			data: res.data.data,
		}
	}
})

export const detailsFAQSlice = createSlice({
	name: 'AllFAQList',
	initialState: {
		loading: false,
		error: {},
		data: {},
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(getDetailsFAQList.pending, (state, action) => {
				state.loading = true
			})
			.addCase(getDetailsFAQList.fulfilled, (state, action) => {
				state.loading = false
				state.data = action.payload.data
			})
			.addCase(getDetailsFAQList.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			}),
})

export default detailsFAQSlice.reducer
