import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { render } from 'react-dom'
import { AgGridReact } from 'ag-grid-react' // the AG Grid React Component
import 'ag-grid-community/dist/styles/ag-grid.css' // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { useTranslation } from 'react-i18next'
import { CheckboxRenderer } from '@/components/dms/ag-grid/checkbox'
import { AttributeInputRenderer } from '@/components/dms/ag-grid/attribute-input'
import useStateListener from '@/core/hooks/useStateListener'
import { useParams } from 'react-router-dom'

export const AgGridFormComposer = ({
	setReference = () => {},
	list = [],
	setList = () => {},
	trigger,
	getData = () => {},
}) => {
	const { id } = useParams()
	const isEditing = !!id

	const ref = useRef()

	const api = ref?.current?.api

	const { t } = useTranslation()

	const [isInitialized, setIsInitialized] = useState(false)
	const [rowData, setRowData] = useStateListener(isEditing ? [] : list, [list])

	const [columnDefs, setColumnDefs] = useState([
		{
			editable: false,
			checkboxSelection: true,
		},
		{
			field: 'tagNumber',
			headerName: t('tagNumber'),
			editable: false,
			cellRenderer: AttributeInputRenderer,
			// cellRendererParams: {
			// 	key: 'this is prop1',
			// },
		},
		{
			field: 'fieldName',
			headerName: t('fieldName'),
			editable: false,
			cellRenderer: AttributeInputRenderer,
		},
		{
			field: 'default',
			headerName: t('default'),
			editable: false,
			cellRenderer: AttributeInputRenderer,
		},
		{
			field: 'secondaryFieldNumber',
			headerName: t('secondaryFieldNumber'),
			editable: false,
			cellRenderer: AttributeInputRenderer,
		},
		{
			field: 'secondaryFieldName',
			headerName: t('secondaryFieldName'),
			editable: false,
			cellRenderer: AttributeInputRenderer,
		},
		{
			field: 'repeatable',
			headerName: t('repeatable'),
			editable: false,
			cellRenderer: CheckboxRenderer,
		},
		{
			field: 'required',
			headerName: t('required'),
			editable: false,
			cellRenderer: CheckboxRenderer,
		},
		{
			field: 'show',
			headerName: t('show'),
			editable: false,
			cellRenderer: CheckboxRenderer,
		},
		{ field: 'edit', headerName: t('edit'), editable: false, cellRenderer: CheckboxRenderer },
	])

	// DefaultColDef sets props common to all Columns
	const defaultColDef = useMemo(() => ({
		cellStyle: { padding: 0 },
		sortable: true,
		// editable: true,
		// filter: true,
	}))

	const initRows = () => {
		const api = ref?.current?.api

		if (isEditing && api && !isInitialized && list?.length) {
			list.map((item) => {
				const newItem = {
					tagNumber: item.tagNumber !== undefined ? item.tagNumber : '',
					fieldName: item.fieldName !== undefined ? item.fieldName : '',
					default: item.default !== undefined ? item.default : '',
					secondaryFieldNumber:
						item.secondaryFieldNumber !== undefined ? item.secondaryFieldNumber : '',
					secondaryFieldName: item.secondaryFieldName !== undefined ? item.secondaryFieldName : '',
					repeatable: item.repeatable !== undefined ? item.repeatable : '',
					required: item.required !== undefined ? item.required : '',
					show: item.show !== undefined ? item.show : '',
					edit: item.edit !== undefined ? item.edit : '',
				}

				// console.log({ item, newItem })

				if (item) {
					api.applyTransaction({
						add: [newItem],
						// addIndex: addIndex,
					})
				}
			})

			setIsInitialized(true)
		}

		return rowData
	}

	const getAllRows = useCallback(() => {
		const api = ref?.current?.api
		let rowData = []

		if (api) {
			api.forEachNode((node) => {
				rowData.push(node.data)
			})

			// setList(rowData)
			getData(rowData)
		}

		return rowData
	}, [api])

	const cellClickedListener = useCallback((event) => {
		// getAllRows()
	}, [])

	const onValueChanged = useCallback((event) => {
		getAllRows()
	}, [])

	useEffect(() => {
		getAllRows()
	}, [api, ref, trigger])

	useEffect(() => {
		setReference(ref)
	}, [ref])

	useEffect(() => {
		initRows()
	}, [api, list])

	return (
		<div>
			<div className='ag-theme-alpine' style={{ height: 250 }}>
				<AgGridReact
					enableRtl={true}
					ref={ref} // Ref for accessing Grid's API
					rowData={rowData} // Row Data for Rows
					columnDefs={columnDefs} // Column Defs for Columns
					defaultColDef={defaultColDef} // Default Column Properties
					animateRows={true} // Optional - set to 'true' to have rows animate when sorted
					rowSelection='multiple' // Options - allows click selection of rows
					rowMultiSelectWithClick={false}
					// onCellClicked={cellClickedListener} // Optional - registering for Grid Event
					onCellValueChanged={onValueChanged}
				/>
			</div>
		</div>
	)
}
