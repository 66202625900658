import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Http } from 'core/helper/Http'

// ********************* USER Add FAQ FUNCTIONALITY*********************

export const getFAQList = createAsyncThunk('AllFAQList/AllFAQ', async () => {
	const res = await Http(`faq`, {
		method: 'get',
	})
	if (res.status === 200) {
		return {
			data: res.data.data,
		}
	}
})

export const faqListSlice = createSlice({
	name: 'AllFAQList',
	initialState: {
		loading: false,
		error: {},
		data: [],
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(getFAQList.pending, (state, action) => {
				state.loading = true
			})
			.addCase(getFAQList.fulfilled, (state, action) => {
				state.loading = false
				state.data = action.payload.data
			})
			.addCase(getFAQList.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			}),
})

export default faqListSlice.reducer
