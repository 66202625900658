import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Http } from 'core/helper'

export const getPublicProfile = createAsyncThunk('GetPublicProfile/user', async (id) => {
	const res = await Http(`user/profile/public/${id}`, { method: 'get' })
	if (res.status === 200) {
		return { data: res.data }
	}
})

const getPublicProfileSlice = createSlice({
	name: 'GetPublicProfile',
	initialState: {
		data: [],
		loading: false,
		error: {},
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(getPublicProfile.pending, (state) => {
				state.loading = true
			})
			.addCase(getPublicProfile.fulfilled, (state, action) => {
				state.data = action?.payload?.data
				state.loading = false
			})
			.addCase(getPublicProfile.rejected, (state, action) => {
				state.data = null
				state.loading = false
				state.errors = action.payload
			}),
})

export default getPublicProfileSlice.reducer
