import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ConfirmDialog = (props) => {
	const { t } = useTranslation()

	const { title, children, open, setOpen, onConfirm } = props
	return (
		<Dialog
			dir={'rtl'}
			sx={{ textAlign: 'right' }}
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby='confirm-dialog'
		>
			<DialogTitle id='confirm-dialog'>{t(title)}</DialogTitle>
			<DialogContent>{children}</DialogContent>
			<DialogActions sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
				<Button variant='contained' onClick={() => setOpen(false)} color='primary'>
					{t('No')}
				</Button>
				<Button
					variant='contained'
					onClick={() => {
						setOpen(false)
						onConfirm()
					}}
					color='primary'
				>
					{t('Yes')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmDialog
