import React from 'react'
import {
	Avatar,
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material'
import { SideArticle } from '@/pages/document/Article/Article-Styled'
import { CloudDownload, CloudUpload } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Download } from '../../../core/helper'
import DmsSidebarTop from './top'
import { getIconByMimType } from '@/core/helper/attachments'
import { updateDms } from '@/core/redux/features/dms/update'
import { showDms } from '@/core/redux/features/dms/show'
import { Dropzone, FileItem } from '@dropzone-ui/react'
import { useDialog } from '@/core/hooks/useDialog'

const DmsSidebar = ({ data, showSide, setShowSide }) => {
	const { t } = useTranslation()

	return (
		<SideArticle IsMobileSide={showSide}>
			<Stack direction={'column'} gap={2.5}>
				<DmsSidebarTop t={t} data={data} setShowSide={setShowSide} />
				<FilesList data={data?.drives} />
			</Stack>
		</SideArticle>
	)
}

const FilesList = ({ data }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const { data: dms, loading } = useSelector((state) => state.showDmsSlice)

	const { setOpen: setIsDialogOpen, open: isDialogOpen } = useDialog()

	const [files, setFiles] = React.useState([])

	const updateFiles = (incomingFiles) => {
		setFiles(incomingFiles)
	}

	const refresh = () => {
		dispatch(showDms({ id: dms.id, page: 1, perPage: 5 }))
	}

	const uploadAttachments = () => {
		dispatch(updateDms({ id: dms.id, files }))
			.unwrap()
			.then((r) => {
				refresh()
				setFiles([])
			})
	}

	const deleteAttachment = (id) => {
		dispatch(updateDms({ id: dms.id, fileToRemove: id }))
			.unwrap()
			.then((r) => {
				refresh()
			})
	}

	return (
		<Box mb={2}>
			<Box>
				<Typography mb={2}>{t('attachments')}</Typography>
				<List sx={{ width: '100%', maxWidth: 360 }}>
					{!!data?.length && (
						<>
							{data.map(({ url, title, id, file }, index) => {
								const { icon } = getIconByMimType(url)

								const name = url.split('/')[6]

								return (
									<ListItem dir={'ltr'} key={index}>
										<ListItemAvatar>
											<Avatar>{icon}</Avatar>
										</ListItemAvatar>
										<div>
											<ListItemText
												primary={title}
												secondary={file}
												secondaryTypographyProps={{
													sx: {
														wordBreak: 'break-all',
													},
												}}
											/>
											<Box gap={1} display={'flex'}>
												<IconButton onClick={() => Download(name, file)} color='info'>
													<CloudDownload />
												</IconButton>
												{/*<IconButton*/}
												{/*	onClick={(e) => {*/}
												{/*		e.preventDefault()*/}
												{/*		e.stopPropagation()*/}

												{/*		setIsDialogOpen(true)*/}
												{/*	}}*/}
												{/*	color='error'*/}
												{/*>*/}
												{/*	<Delete />*/}
												{/*</IconButton>*/}

												{/*<ConfirmDialog*/}
												{/*	title={'Delete_Template'}*/}
												{/*	open={isDialogOpen}*/}
												{/*	setOpen={setIsDialogOpen}*/}
												{/*	onConfirm={() => {*/}
												{/*		deleteAttachment(id)*/}
												{/*	}}*/}
												{/*>*/}
												{/*	{t('Are_You_Sure_Deleting?')}*/}
												{/*</ConfirmDialog>*/}

												{/*<DeleteForeverOutlined*/}
												{/*	onClick={(e) => {*/}
												{/*		e.preventDefault()*/}
												{/*		e.stopPropagation()*/}

												{/*		deleteAttachment(id)*/}
												{/*	}}*/}
												{/*	sx={{ cursor: 'pointer' }}*/}
												{/*/>*/}
											</Box>
										</div>
									</ListItem>
								)
							})}
						</>
					)}
				</List>
			</Box>
			{!!false && (
				<>
					<Divider />
					<Box mt={2}>
						<Dropzone
							footer={false}
							header={true}
							label={
								<Grid gap={2} alignItems={'center'} justifyContent={'center'} container>
									<CloudUpload
										sx={{
											fontSize: '100px',
										}}
									/>
									<span>{t('Select_Attachment')}</span>
								</Grid>
							}
							onChange={updateFiles}
							value={files}
						>
							{files.map((file) => (
								<FileItem {...file} preview />
							))}
						</Dropzone>
						{!!files?.length && (
							<Button
								variant={'contained'}
								fullWidth
								sx={{ marginTop: '10px' }}
								onClick={uploadAttachments}
							>
								{t('Upload')}
							</Button>
						)}
					</Box>
				</>
			)}
		</Box>
	)
}

export default DmsSidebar
