import { Button, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ComposeSectionItem } from './item'
import { AgGridFormComposer } from '@/components/dms/ag-grid'
import React, { useEffect, useState } from 'react'

export const ComposeSection = ({
	data: fetchedData,
	list = [{}],
	setList = () => {},
	trigger,
	setRef = () => {},
}) => {
	let data = { ...fetchedData }

	const { t } = useTranslation()

	const addNew = () => {
		setList([...list, { section: '', detail: [{}] }])
	}

	useEffect(() => {
		// if (data?.attributes?.length) {
		// 	// console.log(data.attributes)
		//
		// 	setList(
		// 		data.attributes.map((item) => {
		// 			return {
		// 				section: item.title,
		// 				details: item.detail,
		// 			}
		// 		})
		// 	)
		// }
	}, [data?.attribute?.length])

	// console.log({ list })

	return (
		<Grid container spacing={4}>
			<Grid xs={12} item>
				<Grid container spacing={2}>
					{list.map((item, index) => {
						// console.log(index, { item, list })

						return (
							<Grid key={index} xs={12} item>
								<ComposeSectionItem
									trigger={trigger}
									data={item}
									list={list}
									setList={setList}
									index={index}
									// setRef={setRef}
								/>
							</Grid>
						)
					})}
				</Grid>
			</Grid>

			<Grid xs={12} item>
				<Button onClick={addNew} type={'button'} variant='contained'>
					{t('Create_New_Dms_Section')}
				</Button>
			</Grid>
		</Grid>
	)
}
