import React from "react";
import { Drawer } from "@mui/material";
const MobileHeader = () => {
  return (
    <>
      <Drawer anchor={"left"}>mamamd mamamd mamamd</Drawer>
    </>
  );
};

export default MobileHeader;
