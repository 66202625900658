import GlobalSearchInput from '@/components/Input/GlobalSearchInput'
import { Box, Card } from '@mui/material'

export const HeaderSearch = () => {
	return (
		<Card sx={{ padding: 0 }}>
			<GlobalSearchInput />
		</Card>
	)
}
