import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Alert, getError } from 'core/helper'
import { Http } from 'core/helper/Http'

// ********************* USER Add FAQ FUNCTIONALITY*********************

export const addFAQ = createAsyncThunk('AddFAQ/AddFAQ', async (data, { rejectWithValue }) => {
	try {
		const res = await Http(`faq`, {
			method: 'post',
			data,
		})
		if (res.status === 200) {
			Alert.SUCCESS(res.data.message)
			return {
				data: res.data,
			}
		}
	} catch (error) {
		Alert.ERROR(getError(error))

		rejectWithValue(getError(error))
	}
})

export const addFAQSlice = createSlice({
	name: 'AddFAQ',
	initialState: {
		loading: false,
		error: {},
		isSuccess: null,
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(addFAQ.pending, (state, action) => {
				state.loading = true
				state.isSuccess = null
			})
			.addCase(addFAQ.fulfilled, (state, action) => {
				state.loading = false
				state.isSuccess = true
			})
			.addCase(addFAQ.rejected, (state, action) => {
				state.loading = false
				state.isSuccess = false
				state.error = action.payload
			}),
})

export default addFAQSlice.reducer
