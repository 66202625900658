import React, { Component, useEffect } from 'react'

export const CheckboxRenderer = (props) => {
	const field = props.colDef?.field
	let colId = props.column.colId
	const defaultValue = props.data?.[field] || props.value || false

	const checkedHandler = (e) => {
		if (e?.target) {
			const { checked } = e.target

			return props.node.setDataValue(colId, checked)
		}

		props.node.setDataValue(colId, defaultValue)
	}

	useEffect(checkedHandler, [])

	return <input type='checkbox' onChange={checkedHandler} checked={props.value} />
}
