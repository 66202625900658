import React, { useEffect, useState } from 'react'

export default function useStateListener(data, deps = []) {
	const [state, setState] = useState(data)

	useEffect(() => {
		setState(data)
	}, [...deps])

	return [state, setState]
}
