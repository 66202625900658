import { Box, Button, Card, CardContent, Divider, Grid, IconButton } from '@mui/material'
import { CustomInput } from '../../../index'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ComposeDmsAttributes } from '../attributes'
import useStateListener from '../../../../core/hooks/useStateListener'
import { AgGridFormComposer } from '@/components/dms/ag-grid'
import { AddCard, AddRounded, Delete } from '@mui/icons-material'
import ConfirmDialog from '@/components/confirm-dialog'
import { useDialog } from '@/core/hooks/useDialog'

export const ComposeSectionItem = ({
	data = {},
	index = 0,
	list = [],
	setList = () => {},
	setRef = () => {},
	trigger,
}) => {
	const isEditing = data?.id

	const [tableRef, setTableRef] = useState(null)

	const { setOpen: setIsDeleteSectionOpen, open: isDeleteSectionOpen } = useDialog()
	const { setOpen: setIsDeleteRowsOpen, open: isDeleteRowsOpen } = useDialog()

	const [composedAttributes, setComposedAttributes] = useStateListener(
		isEditing ? data.detail : [],
		[isEditing]
	)

	const [attributes, setAttributes] = useStateListener(data.detail || undefined, [data])

	const { t } = useTranslation()

	const setDynamicAttributes = (data) => {
		setComposedAttributes(data)
	}

	const handleTitleChange = (e) => {
		const { value } = e.target

		setList(
			list.map((item, listIndex) => {
				if (listIndex === index) {
					item = { ...item, section: value }
				}

				return item
			})
		)
	}

	const handleAttributeChange = () => {
		setList(
			list.map((item, listIndex) => {
				if (listIndex === index) {
					item = { ...item, detail: composedAttributes }

					// console.log(item, { composedAttributes })

					// console.log('handleAttributeChange', item)
				}

				return item
			})
		)
	}

	const deleteSection = () => {
		const filtered = list.filter((item, listIndex) => {
			return listIndex !== index
		})

		setList(filtered)
	}

	const deleteRows = () => {
		const selectedRows = tableRef?.current?.api?.getSelectedRows()

		if (selectedRows?.length) tableRef?.current?.api?.applyTransaction?.({ remove: selectedRows })
	}

	const addAttribute = () => {
		tableRef?.current?.api?.applyTransaction?.({ add: [{}] })

		// setAttributes([...attributes, emptyAttribute])
	}

	useEffect(handleAttributeChange, [composedAttributes])

	// useEffect(() => {
	// 	setRef?.(tableRef)
	// }, [tableRef])

	// console.log({ attributes })

	return (
		<Card sx={{ minWidth: 275 }}>
			<CardContent>
				<Grid container alignItems={'center'} justifyContent={'space-between'}>
					<Grid item xs={12} md={4}>
						<CustomInput
							title={t('Section_Title')}
							id={`section-title-${index}`}
							required={false}
							onBlur={handleTitleChange}
							defaultValue={data.title || data.section || ''}
						/>
					</Grid>
				</Grid>

				<Divider />

				<AgGridFormComposer
					trigger={trigger}
					setReference={setTableRef}
					list={attributes}
					setList={setAttributes}
					getData={setDynamicAttributes}
				/>
				{/*<ComposeDmsAttributes list={attributes} setList={setAttributes} />*/}

				<Box sx={{ display: 'flex', paddingTop: 1, paddingBottom: 1 }} gap={2}>
					<Button
						title={t('Create_New_Dms_Attribute')}
						variant='outlined'
						onClick={() => {
							addAttribute()
						}}
						color='info'
					>
						<AddRounded />
						{t('Create_New_Dms_Attribute')}
					</Button>

					<Button
						onClick={() => {
							setIsDeleteRowsOpen(true)
						}}
						title={t('Delete_Selected_Attributes')}
						variant='outlined'
						color={'error'}
					>
						<Delete />
						{t('Delete_Selected_Attributes')}
					</Button>

					<ConfirmDialog
						title={t('Delete_Selected_Attributes')}
						open={isDeleteRowsOpen}
						setOpen={setIsDeleteRowsOpen}
						onConfirm={() => {
							deleteRows()
						}}
					>
						{t('Are_You_Sure_Deleting?')}
					</ConfirmDialog>

					<Button
						onClick={() => {
							setIsDeleteSectionOpen(true)
						}}
						title={t('Delete_Template')}
						variant='contained'
						color={'error'}
					>
						<Delete />
						{t('Delete_Template')}
					</Button>

					<ConfirmDialog
						title={t('Delete_Template')}
						open={isDeleteSectionOpen}
						setOpen={setIsDeleteSectionOpen}
						onConfirm={() => {
							deleteSection()
						}}
					>
						{t('Are_You_Sure_Deleting?')}
					</ConfirmDialog>
				</Box>
			</CardContent>
		</Card>
	)
}
