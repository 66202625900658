import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FirstLoadingCore, LoadingCore } from 'components/core'
import DmsBody from '../../components/dms/body'
import { showDms } from '@/core/redux/features/dms/show'
import { DmsTemplatesShow } from '@/core/redux/features/dms/templates/show'

const ShowDms = ({ type = '' }) => {
	const isTemplate = type === 'template'

	const { id } = useParams()

	const { data, loading } = useSelector((state) =>
		!isTemplate ? state.showDmsSlice : state.showDmsTemplateSlice
	)
	const { data: user } = useSelector((state) => state.user)
	const dispatch = useDispatch()
	const ref = useRef(false)

	const fetch = () => {
		const dispatcher = isTemplate ? DmsTemplatesShow : showDms

		dispatch(dispatcher({ id: id, page: 1, perPage: 5 }))
	}

	useEffect(fetch, [dispatch, id])

	useEffect(() => {
		if (loading) {
			ref.current = true
		}
	}, [loading])

	return !ref.current && loading ? (
		<FirstLoadingCore loading={loading} />
	) : (
		<>
			{loading && <LoadingCore loading={loading} />}

			<DmsBody status={data?.status_type} type={type || data?.type} user={user?.data} />
		</>
	)
}

export default ShowDms
