import React, { useContext, useState } from 'react'
import { Item } from 'components/core'
import { Stack, Tooltip, tooltipClasses, Box } from '@mui/material'
import { WrapperIcon } from './Sidebar-styled'
import { Link, useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
	CloudDone,
	FolderOpen,
	Person,
	Settings,
	Window,
	Group,
	EventNote,
	Gavel,
	PowerSettingsNewSharp,
	DoubleArrowSharp,
	DocumentScanner,
	SpeakerNotes,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { AppContext } from 'components/context/AppContext'
import SignOutModal from './SignOutModal'
import { useSelector } from 'react-redux'
import { CheckRole } from '../../core/helper'

const Tabs = ({ handleMobileSidebar }) => {
	const { sidebar, setSidebarBar } = useContext(AppContext)
	const [openModal, setOpenModal] = useState(false)
	const { t } = useTranslation()
	let { pathname } = useLocation()
	const { data: user } = useSelector((state) => state.user)

	const CustomTooltip = styled(({ className, ...props }) => (
		<Tooltip
			dir={t('direction') === 'rtl' ? 'left' : 'right'}
			placement={t('direction') === 'rtl' ? 'left' : 'right'}
			componentsProps={{
				tooltip: {
					sx: {
						bgcolor: '#322740',
						fontSize: '15px ',
						'& .MuiTooltip-arrow': {
							color: '#322740',
							'& .MuiTooltip-arrow:before': {
								right: 0,
							},
						},
					},
				},
			}}
			{...props}
			arrow
			classes={{ popper: className }}
		/>
	))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: '#322740',
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#322740',
		},
	}))

	return (
		<>
			<Stack flexDirection={'column'} display={'flex'} spacing={2}>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
					<Box>
						<Link to={'/document'}>
							<Item onClick={() => handleMobileSidebar()} isActive={pathname === '/document'}>
								<CustomTooltip title={t('Documents')}>
									<WrapperIcon>
										<DocumentScanner />
									</WrapperIcon>
								</CustomTooltip>
							</Item>
						</Link>
						{CheckRole({
							roles: user?.data?.type,
							page: 'dms',
							part: 'view',
						}) && (
							<Link to={'/dms'}>
								<Item onClick={() => handleMobileSidebar()} isActive={pathname === '/dms'}>
									<CustomTooltip title={t('Document')}>
										<WrapperIcon>
											<FolderOpen />
										</WrapperIcon>
									</CustomTooltip>
								</Item>
							</Link>
						)}
						<Link to={'/users'}>
							<Item onClick={() => handleMobileSidebar()} isActive={pathname === '/users'}>
								<CustomTooltip title={t('Users')}>
									<WrapperIcon>
										<Group />
									</WrapperIcon>
								</CustomTooltip>
							</Item>
						</Link>
						{CheckRole({
							roles: user?.data?.type,
							page: 'tabs',
							part: 'vacation',
						}) && (
							<Link to={'/vacation'}>
								<Item onClick={() => handleMobileSidebar()} isActive={pathname === '/vacation'}>
									<CustomTooltip title={t('vacation')}>
										<WrapperIcon>
											<EventNote />
										</WrapperIcon>
									</CustomTooltip>
								</Item>
							</Link>
						)}
						{CheckRole({
							roles: user?.data?.type,
							page: 'tabs',
							part: 'projects',
						}) && (
							<Link to={'/projects'}>
								<Item onClick={() => handleMobileSidebar()} isActive={pathname === '/projects'}>
									<CustomTooltip title={t('project')}>
										<WrapperIcon>
											<img src='/assets/images/analytics.png' alt='project' />
										</WrapperIcon>
									</CustomTooltip>
								</Item>
							</Link>
						)}
						{CheckRole({
							roles: user?.data?.type,
							page: 'tabs',
							part: 'tickets',
						}) && (
							<Link to={'/tickets'}>
								<Item onClick={() => handleMobileSidebar()} isActive={pathname === '/tickets'}>
									<CustomTooltip title={t('Tickets')}>
										<WrapperIcon>
											<img src='/assets/icon/tickets.png' alt='tickets' />
										</WrapperIcon>
									</CustomTooltip>
								</Item>
							</Link>
						)}

						<Link to={'/drive'}>
							<Item onClick={() => handleMobileSidebar()} isActive={pathname === '/drive'}>
								<CustomTooltip title={t('Drive')}>
									<WrapperIcon>
										<CloudDone />
									</WrapperIcon>
								</CustomTooltip>
							</Item>
						</Link>
						<Link to={'/rules'}>
							<Item onClick={() => handleMobileSidebar()} isActive={pathname === '/rules'}>
								<CustomTooltip title={t('Rules')}>
									<WrapperIcon>
										<Gavel />
									</WrapperIcon>
								</CustomTooltip>
							</Item>
						</Link>
						<Link to={'/faq'}>
							<Item onClick={() => handleMobileSidebar()} isActive={pathname === '/faq'}>
								<CustomTooltip title={t('FAQ')}>
									<WrapperIcon>
										<SpeakerNotes />
									</WrapperIcon>
								</CustomTooltip>
							</Item>
						</Link>
						{CheckRole({
							roles: user?.data?.type,
							page: 'tabs',
							part: 'config',
						}) && (
							<Link to={'/config-workspace'}>
								<Item onClick={() => handleMobileSidebar()} isActive={pathname === '/config'}>
									<CustomTooltip title={t('config')}>
										<WrapperIcon>
											<Settings />
										</WrapperIcon>
									</CustomTooltip>
								</Item>
							</Link>
						)}
					</Box>
					<Box sx={{ position: 'absolute', bottom: '5px' }}>
						<Item>
							<CustomTooltip title={t('SignOut')}>
								<WrapperIcon onClick={() => setOpenModal(!openModal)}>
									<PowerSettingsNewSharp />
								</WrapperIcon>
							</CustomTooltip>
						</Item>
						<Item onClick={() => handleMobileSidebar()} isActive={pathname === '/user-panel'}>
							<CustomTooltip title={t('User_Profile')}>
								<WrapperIcon>
									<Link to={'/user-panel'}>
										<Person />
									</Link>
								</WrapperIcon>
							</CustomTooltip>
						</Item>
						<Item>
							{/* <CustomTooltip title={t("config")}> */}
							{sidebar ? (
								<WrapperIcon onClick={() => setSidebarBar(!sidebar)}>
									<DoubleArrowSharp />
								</WrapperIcon>
							) : (
								<WrapperIcon onClick={() => setSidebarBar(!sidebar)}>
									<DoubleArrowSharp sx={{ transform: 'rotate(180deg)' }} />
								</WrapperIcon>
							)}
							{/* </CustomTooltip> */}
						</Item>
					</Box>
				</Box>
			</Stack>
			{openModal && <SignOutModal openModal={openModal} setOpenModal={setOpenModal} />}
		</>
	)
}

export default Tabs
