import { CustomInput } from '@/components'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

export const DynamicForm = ({ data, setRef = () => {} }) => {
	const { t } = useTranslation()
	const { id } = useParams()
	const isEditing = !!id

	const {
		getValues,
		register,
		handleSubmit,
		formState: { errors },
		setValue: setFormValue,
		reset,
	} = useForm({
		defaultValues: {},
	})

	useEffect(() => {
		setRef({ getValues, reset, errors, setFormValue })
	}, [getValues])

	useEffect(() => {
		reset({})
	}, [data])

	return (
		<>
			{!!data?.length && (
				<div>
					{data.map((item, index) => {
						const { detail, title, section, id } = item

						return (
							<div key={index}>
								<h4>{title || section}</h4>

								<form>
									<Grid spacing={2} container>
										{isEditing ? (
											<>
												{!!Object.keys(detail)?.length && (
													<>
														{Object.keys(detail).map((item, index) => {
															const fieldName = item
															const defaultValue = detail[item] || ''

															return (
																<Grid item xs={12} md={4} key={index}>
																	<CustomInput
																		register={register}
																		defaultValue={defaultValue}
																		title={t(fieldName)}
																		field={`section:${section}-field:${fieldName}`}
																	/>
																</Grid>
															)
														})}
													</>
												)}
											</>
										) : (
											<>
												{!!detail?.length && (
													<>
														{detail.map((item, index) => {
															const {
																default: defaultValue,
																edit = false,
																fieldName,
																required = false,
																show = false,
																repeatable = false,
																secondaryFieldName,
																secondaryFieldNumber,
																tagNumber,
															} = item

															return (
																<Grid item xs={12} md={4} key={index}>
																	{!!show && !!fieldName && (
																		<CustomInput
																			register={register}
																			defaultValue={defaultValue}
																			// value={!edit ? defaultValue : undefined}
																			required={required}
																			title={t(fieldName)}
																			field={`section:${title}-field:${fieldName}`}
																			data-details={JSON.stringify(item)}
																			readOnly={!edit}
																			disabled={!edit}
																		/>
																	)}
																</Grid>
															)
														})}
													</>
												)}
											</>
										)}
									</Grid>
								</form>
							</div>
						)
					})}
				</div>
			)}
		</>
	)
}
