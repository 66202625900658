import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Alert, Http } from 'core/helper'

export const showDms = createAsyncThunk('ShowDms/Dms', async ({ id }, { rejectWithValue }) => {
	// Alert.DEFAULT('در حال بارگزاری')

	const res = await Http(`document/show/dms/${id}`, {
		method: 'get',
	})

	if (res.status === 200) {
		return { data: res.data?.data }
	} else {
		Alert.ERROR(res.response.data.error)
		throw new Error(res.response.data.error)
	}
})

export const showDmsSlice = createSlice({
	name: 'ShowDms',
	initialState: {
		data: {},
		loading: false,
		error: {},
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(showDms.pending, (state) => {
				state.loading = true
			})
			.addCase(showDms.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.loading = false
			})
			.addCase(showDms.rejected, (state, action) => {
				state.data = null
				state.loading = false
				state.error = action.payload
			}),
})

export default showDmsSlice.reducer
