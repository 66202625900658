import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Alert, Http } from 'core/helper'

export const AddCategory = createAsyncThunk(
	'AddCategory/Categories',
	async ({ data, method = 'post', id = '', type = '', is_formal = 0 }, { rejectWithValue }) => {
		const isDmsTemplate = type === 'dms_template'
		const isDms = type === 'dms' || isDmsTemplate

		if (isDms) {
			is_formal = 1
		}

		let url = null

		if (method === 'put') {
			url = `document/category/${id}`
		} else {
			url = `document/category`
		}

		data.content = type
		data.is_formal = is_formal

		const res = await Http(url, { method: method, data })
		if (res.status === 200) {
			return { data: res.data?.data }
		} else {
			Alert.ERROR(res.response.data.error)
			throw new Error(res.response.data.error)
		}
	}
)

const AddCategorySlice = createSlice({
	name: 'AddCategory',
	initialState: {
		data: {},
		loading: false,
		isSuccess: null,
		error: {},
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(AddCategory.pending, (state) => {
				state.loading = true
				state.isSuccess = null
			})
			.addCase(AddCategory.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.loading = false
				state.isSuccess = true
			})
			.addCase(AddCategory.rejected, (state, action) => {
				state.data = null
				state.loading = false
				state.isSuccess = false
				state.error = action.payload
			}),
})

export default AddCategorySlice.reducer
