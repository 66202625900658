import { render } from 'react-dom'
import App from './components/App'
import { Provider } from 'react-redux'
import { store } from '@/core/redux/store'
import ThemeContext from './components/context/ThemeContext'
import AppProvider from './components/context/AppContext'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import 'react-quill/dist/quill.snow.css'
import 'assets/index.css'

let persistor = persistStore(store)

render(
	<Provider store={store}>
		<ThemeContext>
			<AppProvider>
				<PersistGate loading={null} persistor={persistor}>
					<App />
				</PersistGate>
			</AppProvider>
		</ThemeContext>
	</Provider>,
	document.getElementById('root')
)
