import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Alert } from 'core/helper'
import { Http } from 'core/helper/Http'

export const CommentDocument = createAsyncThunk(
	'CommentDocument/likeStuff',
	async (data, { rejectWithValue }) => {
		try {
			const res = await Http(`/document/comment`, {
				method: 'post',
				data,
			})
			if (res.status === 200) {
				Alert.SUCCESS(res.data.data)
				return {
					data: res.data,
				}
			} else {
				throw new Error(res.response.data.error)

				// return {
				// 	data: rejectWithValue(res.response.data.error),
				// 	isOk: false,
				// }
			}
		} catch (error) {
			Alert.ERROR(error?.message || error?.response?.data?.error || '')
			rejectWithValue(error.response.data.error)
		}
	}
)

export const CommentDocumentSlice = createSlice({
	name: 'CommentDocument',
	initialState: {
		loading: false,
		error: {},
		isSuccess: null,
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(CommentDocument.pending, (state, action) => {
				state.loading = true
				state.isSuccess = null
			})
			.addCase(CommentDocument.fulfilled, (state, action) => {
				state.loading = false
				state.isSuccess = true
			})
			.addCase(CommentDocument.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
				state.isSuccess = false
			}),
})

export default CommentDocumentSlice.reducer
