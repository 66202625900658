const CheckRole = ({ roles, page, part }) => {
	let check = false

	for (let i = 0; i < roles?.length; i++) {
		const permissions = pages?.[page]?.[part]

		if (permissions?.[roles?.[i]] === true) {
			check = true
			break
		}
	}
	return check
}

const roles = {
	manager: true,
	admin: false,
	advanced: false,
	hr: false,
	user: false,
	expert: false,
	guest: false,
	archivist: false,
	archiveManager: false,
}

const rolesTrue = {
	manager: true,
	admin: true,
	advanced: true,
	hr: true,
	user: true,
	expert: true,
	guest: true,
	archivist: true,
	archiveManager: true,
}

export default CheckRole

let pages = {
	document: {
		side: { ...roles, advanced: true, admin: true },
		categories: { ...roles, advanced: true, admin: true },
		changeStatus: { ...roles, advanced: true, admin: true },
		storeArticle: { ...roles, advanced: true, admin: true, expert: true },
		store: { ...roles, advanced: true, admin: true, expert: true },
		visibility: { ...roles, advanced: true, admin: true },
		deprecate: { ...roles, advanced: true, admin: true },
		edit: { ...roles, advanced: true, admin: true },
		contribute: { ...rolesTrue, guest: false },
		manageComments: { ...roles, advanced: true, admin: true },
		makePublic: { ...roles, advanced: true, admin: true },
		forceShowArticle: {
			...roles,

			advanced: true,
		},
	},
	get kms() {
		return pages.document
	},
	dms: {
		changeStatus: { ...roles, advanced: true, admin: true },
		view: { ...roles, archivist: true, archiveManager: true },
		store: { ...roles, archivist: true, archiveManager: true },
		side: { ...roles, archivist: true, archiveManager: true },
		categories: { ...roles, archivist: true, archiveManager: true },
	},
	get dms_template() {
		return {
			...pages.dms,
			categories: { ...roles, archivist: true, archiveManager: true },
		}
	},
	users: {
		updateRole: { ...roles, manager: true },
		mobile: { ...roles, manager: true },
		updateInfo: { ...roles, manager: true },
	},
	tabs: {
		vacation: { ...roles, hr: true },
		projects: { ...roles, advanced: true, admin: true, user: true, expert: true },
		config: { ...roles, manager: true },
		tickets: { ...roles, manager: true },
	},
	projects: {
		store: { ...roles, advanced: true, admin: true },
		update: { ...roles, advanced: true, admin: true },
	},
	drives: {
		delete: { ...roles, manager: true },
		update: { ...roles, manager: true },
	},
	rules: {
		store: { ...roles, manager: true },
		action: { ...roles, manager: true },
	},
	faq: {
		store: { ...roles, manager: true },
		action: { ...roles, manager: true },
	},
	workspaceInfo: {
		update: { ...roles, manager: true },
	},
	config: {
		sidebar: { ...roles, manager: true },
	},

	routes: {
		'/users': { ...rolesTrue, guest: false },
		'/users/:id': { ...rolesTrue, guest: false },
		'/drive': { ...rolesTrue },
		'/myDrive': { ...rolesTrue },
		'/teams': { ...rolesTrue, guest: false },
		'/projects': { ...rolesTrue, guest: false },
		'/tickets': { ...roles, manager: true },
		'/dashboard': { ...rolesTrue, guest: false },
		'/user-panel': { ...rolesTrue },
		'/notification': { ...rolesTrue },
		'/my-vacation': { ...rolesTrue, guest: false },
		'/vacation': { ...roles, hr: true },
		'/document/articles': { ...rolesTrue },
		'/document/store-article': { ...rolesTrue, guest: false },
		'/document/store-wiki': { ...rolesTrue, guest: false },
		'/document/store-draft': { ...rolesTrue, guest: false },
		'/document/tags': { ...roles, advanced: true, admin: true },
		'/document/bookmarks': { ...rolesTrue },
		'/document/drafts': { ...rolesTrue, guest: false },
		'/document/mine': { ...rolesTrue, guest: false },
		'/document/templates': { ...roles, advanced: true, admin: true },
		'/document/wikis': { ...rolesTrue },
		'/document': { ...rolesTrue },
		'/dms': { ...roles, archivist: true, archiveManager: true },
		'/dms/store-dms': {
			...roles,

			archivist: true,
			archiveManager: true,
		},
		'/dms/:id': {
			...roles,

			archivist: true,
			archiveManager: true,
		},
		'/dms/update/:id': {
			...roles,

			archivist: true,
			archiveManager: true,
		},
		'/dms/categories': { ...roles, archivist: true, archiveManager: true },
		'/dms/templates': { ...roles, archivist: true, archiveManager: true },
		'/dms/templates/categories': { ...roles, archivist: true, archiveManager: true },
		'/dms/templates/:id': {
			...roles,

			archivist: true,
			archiveManager: true,
		},
		'/dms/templates/update/:id': {
			...roles,

			archivist: true,
			archiveManager: true,
		},
		'/dms/templates/compose': { ...roles, archivist: true, archiveManager: true },

		'/archives': { ...roles, advanced: true, admin: true },
		'/rules': { ...rolesTrue },
		'/faq': { ...rolesTrue },
		'/config': { ...rolesTrue },
		'/config-leaves': { ...roles, manager: true },
		'/config-evaluate': { ...roles, manager: true },
		'/config-workspace': { ...rolesTrue },
		'/my-studytime': { ...rolesTrue, guest: false },
		'/document/comments/:id': { ...roles, advanced: true, admin: true },
		'/document/article/:id': { ...rolesTrue },
		'/document/wiki/:id': { ...rolesTrue },
		'/document/draft/:id': { ...rolesTrue },
		'/document/knowledge/update': { ...rolesTrue, guest: false },
		'/vacations/:id': { ...roles, hr: true },
		'/project/:id': { ...rolesTrue },
		'/team/:id': { ...rolesTrue },
		'/ticket/:id': { ...roles, manager: true },
		'/archive-tasks/:id': { ...roles, advanced: true, admin: true },
		'/document/public/show/:id': { ...rolesTrue },
		'/document/myKnowledge': { ...rolesTrue, guest: false },
		'/document/update': { ...rolesTrue, guest: false },
		'/document/contributes/:id': { ...rolesTrue, guest: false },
	},
	admin: {
		check: { ...roles, manager: true, advanced: true, admin: true },
	},
}
