import { Card, ListItem, ListItemText } from '@mui/material'
import React from 'react'

export const AttributeItem = ({ data = {}, type = '' }) => {
	const { key, value } = data

	return (
		<Card>
			<ListItem
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					gap: 2,
					flexDirection: 'column',
					alignItems: 'flex-start',
				}}
			>
				<ListItemText primary={key} sx={{ textAlign: 'right' }} />
				<ListItemText secondary={value} />
			</ListItem>
		</Card>
	)
}
