import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Alert, Http } from 'core/helper'

export const setPublicProfile = createAsyncThunk('SetPublicProfile/user', async ({ id, data }) => {
	const res = await Http(`user/updateProfile/${id}`, { method: 'PUT', data })
	if (res.status === 200) {
		Alert.SUCCESS('تغییرات با موفقیت اعمال شد')

		return { data: res.data }
	}
})

const setPublicProfileSlice = createSlice({
	name: 'SetPublicProfile',
	initialState: {
		data: [],
		loading: false,
		error: {},
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(setPublicProfile.pending, (state) => {
				state.loading = true
			})
			.addCase(setPublicProfile.fulfilled, (state, action) => {
				state.data = action?.payload?.data
				state.loading = false
			})
			.addCase(setPublicProfile.rejected, (state, action) => {
				state.data = null
				state.loading = false
				state.errors = action.payload
			}),
})

export default setPublicProfileSlice.reducer
