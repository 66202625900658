import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { _searchAttribute } from '@/core/redux/features/dms/attributes/search'
import {
	Autocomplete,
	Box,
	CircularProgress,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ErrorCore } from '../core'
import { useDebounce } from 'use-debounce'
import { _searchGlobal } from '@/core/redux/features/search'
import { useHistory } from 'react-router-dom'

const GlobalSearchInput = ({ ...rest }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	const [value, setValue] = useState('')

	const [debouncedValue] = useDebounce(value, 750)

	const [open, setOpen] = React.useState(false)
	const [searchResults, setSearchResults] = useState([])

	const [loading, setLoading] = useState(open && searchResults.length === 0)

	const search = (query) => {
		setValue(query)

		if (query?.length) {
			setLoading(true)

			dispatch(_searchGlobal({ query }))
				.unwrap()
				.then((r) => {
					if (r?.data?.data?.length) {
						setSearchResults(r?.data?.data)
					} else {
						setSearchResults([])
					}
				})
				.catch((e) => {
					setSearchResults([])
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}

	const onSelect = (item) => {
		if (item.id) history.push(`/document/${item.type}/${item.id}`)
	}

	useEffect(() => {
		search(debouncedValue)
	}, [debouncedValue])

	return (
		<div>
			<Autocomplete
				id='attribute-input'
				open={open}
				onOpen={() => {
					setOpen(true)
				}}
				onClose={() => {
					setOpen(false)
				}}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				getOptionLabel={(option) => option.title}
				filterOptions={(items) => {
					return items
				}}
				options={searchResults}
				onSubmit={(e) => e.preventDefault()}
				loading={loading}
				renderInput={(params) => (
					<TextField
						{...rest}
						{...params}
						sx={{ borderRadius: 0 }}
						placeholder={t('search')}
						onSubmit={(e) => e.preventDefault()}
						onChange={(e) => {
							setValue(e.target.value)
						}}
						value={value}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color='inherit' size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
				// freeSolo
				onChange={(e, value) => {
					onSelect(value)
				}}
			/>
		</div>
	)
}

export default GlobalSearchInput
