import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Alert } from 'core/helper'
import { Http } from 'core/helper/Http'

// ********************* customer Update leave  FUNCTIONALITY*********************
export const updateConfigLeave = createAsyncThunk(
	'UpdateRulesCustomer/UpdateRules',
	async (data, { rejectWithValue }) => {
		try {
			const res = await Http(`customer/config/leaveRequest`, {
				method: 'put',
				data,
			})
			if (res.status === 200) {
				Alert.SUCCESS(res.data.message)
				return {
					data: res.data,
				}
			} else {
				throw new Error(res.response.data.error)
			}
		} catch (error) {
			Alert.ERROR(error?.response?.data.error)
			rejectWithValue(error?.response?.data.error)
		}
	}
)

export const updateConfigLeaveSlice = createSlice({
	name: 'UpdateRulesCustomer',
	initialState: {
		loading: false,
		error: {},
		isSuccess: null,
	},
	reducers: {},
	extraReducers: (b) =>
		b
			.addCase(updateConfigLeave.pending, (state) => {
				state.loading = true
			})
			.addCase(updateConfigLeave.fulfilled, (state) => {
				state.loading = false
				state.isSuccess = true
			})
			.addCase(updateConfigLeave.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			}),
})

export default updateConfigLeaveSlice.reducer
